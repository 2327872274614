/* User Create, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="user-create" class="entity-create">
    <v-card-title>Create User</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-alert v-if="serverErrorMessage"
               type="error">
        {{ serverErrorMessage }}
      </v-alert>
    </v-card-text>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
          <v-btn color="primary"
                 class="me-3"
                 @click="Save">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Save</span>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="secondary"
                 class="me-3"
                 @click="Back">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th colspan="2">USER DETAILS</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Email</td>
            <td>
              <v-text-field label="Email"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'Email').map(x => x.error)"
                            v-model="entity.Email"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>First Name</td>
            <td>
              <v-text-field label="First Name"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'FirstName').map(x => x.error)"
                            v-model="entity.FirstName"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>Last Name</td>
            <td>
              <v-text-field label="Last Name"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'LastName').map(x => x.error)"
                            v-model="entity.LastName"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>

          <tr>
            <td>Password</td>
            <td>
              <v-text-field label="Password"
                            solo-inverted
                            flat
                            :error-messages="serverErrors.filter(x => x.field === 'Password').map(x => x.error)"
                            v-model="entity.Password"
                            :rules="[validators.required]"></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Role</td>
            <td>
              <v-select :items="roles" v-model="entity.Role"
                        label="Role"
                        solo-inverted
                        flat
                        :error-messages="serverErrors.filter(x => x.field === 'Role').map(x => x.error)"
                        :rules="[validators.required]"></v-select>
            </td>
          </tr>

        </tbody>
      </v-simple-table>
    </v-form>

  </v-card>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import * as FrameworkValidators from '@core/utils/validation'
  import * as Validators from '@/datahelpers/validation'
  import DateField from '@/views/helpers/DateField'
  import CountryLookup from '@/views/country/CountryLookup'

  import {
    mdiContentSaveOutline,
    mdiChevronLeft
  } from '@mdi/js'
  import { ref, watch, computed, expose } from '@vue/composition-api'

  export default {
    components: {
      DateField,
      CountryLookup
    },
    setup() {

      const { route, router } = useRouter();

      const entity = ref({
        Enabled: true
      });
      const loading = ref(false);
      const form = ref(null);
      const serverErrors = ref([]);
      const serverErrorMessage = ref(null);
      const roles = [{ text: 'Super User', value: 'super' }, { text: 'Admin', value: 'admin' }, { text: 'Operations', value: 'ops' }, { text: 'Customer Care', value: 'customercare' }]


      const Save = function (e) {

        serverErrorMessage.value = null;
        serverErrors.value = [];

        const valid = form.value.validate();

        if (!valid) {
          return;
        }

        loading.value = true;

        store
          .dispatch('app/createUser', {
            entity: entity.value
          })
          .then(response => {
            loading.value = false;
            router.push({ name: 'user-view', params: { id: response.data.Id } });
          })
          .catch((error) => {
            loading.value = false;
            if (error.error) {
              if (error.message) serverErrorMessage.value = error.message;
              if (error.errors) serverErrors.value = error.errors
            } else {
              console.log(error);
            }
          });
      };

      const Back = () => {
        router.go(-1);
      };

      const validators = {
        ...FrameworkValidators,
        ...Validators
      };

      return {
        Back,
        form,
        roles,
        Save,
        loading,
        entity,
        Display,
        icons: {
          mdiContentSaveOutline,
          mdiChevronLeft
        },
        validators,
        serverErrorMessage,
        serverErrors
      };
    }
  }
</script>

<style lang="scss" scoped>
  .entity-create::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding-right: 0;
  }

  .entity-create::v-deep .v-data-table th:last-child {
    width: 66%;
  }

  .entity-create::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-messages {
    min-height: unset;
  }

  .entity-create::v-deep .v-input .v-messages.error--text {
    margin-bottom: 8px;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-text-field__details {
    min-height: unset;
    margin-bottom: 0;
  }

  .entity-create::v-deep .v-input:not(.error--text) .v-input__slot {
    margin: 0;
    border-radius: 0;
  }

  .entity-create::v-deep .v-input--selection-controls {
    margin: 10px;
  }
</style>

